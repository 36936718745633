@font-face {
    font-family: solomon-sans-book;
    src: url(../fonts/Solomon-Sans-Book.ttf);
}

@font-face {
    font-family: solomon-sans-light;
    src: url(../fonts/Solomon-Sans-Light.ttf)
}

$color-primary: #50070C;
$background-color: #FDFBF8;
$text-color: #6A6A6A;



@import url(../css/media-queries.scss);

body {

    //Temp styling begin here..

        .const_container{
            width: 100%;
            height:100dvh;
            display: grid;
            place-items: center;

            div{
                display: grid;
                place-items: center;
                img{
                    width: 10%;
                }

                h3{
                    text-align: center;
                    line-height: 1.3;
                    color: gray;
                }
            }
        }

    //Temp styling ends here...
    

    margin: 0;
    padding: 0;
    font-family: sans-serif;
    line-height: 1.5;
    background-color: ($background-color);

    .desktop_socials{
        position: absolute;
        right: 2%;
        top: 3%;
        transform: translate(-2%,3%);
        z-index: 10;
       
        a{
            font-size: .8rem;
            color: #50070C;
        }

        p{
            margin: 0;
        }
    }

    //Your Styles begin here...

    .main_container {
        width: 97%;
        height: 98dvh;
        margin: auto;
        position: relative;
        
        

        .flex_box {
            width: 100%;
            height: 10dvh;
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid hsla(0, 0%, 81%, 0.5);
            position: relative;
            pointer-events: none;

            .flex_contact {
                display: flex;
                align-items: center;
                width: fit-content;
                width: 15%;


                .contact {
                    width: max(30px, 10%);
                    box-sizing: border-box;
                    display: flex;
                    align-items: center;
                    flex: none;
                    cursor: pointer;


                    img {
                        width: 100%;
                        border: 5px solid black;
                        border-radius: 50%;
                        cursor: pointer;
                        pointer-events: auto;
                        position: relative;
                        z-index: 3;
                    }

                    .contact_info {
                        position: absolute;
                        width: 2.8%;
                        aspect-ratio: 1 auto;
                        height: 40px;
                        background-color: black;
                        z-index: 2;
                        border-radius: 30px;
                        pointer-events: auto;
                        cursor: pointer;

                        .container {
                            width: 100%;
                            height: inherit;
                            color: rgb(255, 248, 234);
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            display: none;
                            opacity: 0;

                            div {
                                width: 33.33%;
                                cursor: pointer;
                                text-decoration: underline;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                gap: 20px;
                                cursor: pointer;
                                pointer-events: auto;

                                img {
                                    width: 5%;
                                    height: 5%;
                                    object-fit: cover;
                                    border: none;
                                    pointer-events: auto;




                                }

                                p {
                                    cursor: pointer;
                                    font-size: .9rem;
                                }



                                a{
                                    color: rgb(255, 248, 234);
                                }
                            }
                        }
                    }
                }

                .contact_text {
                    margin-left: 30px;

                    p {
                        text-decoration: underline;
                        color: ($text-color);
                        margin: 0;
                        font-size: clamp(.8rem, .8vw, 1rem);
                    }
                }
            }

            .peniel_flex {
                width: min(180px, 30%);
                position: absolute;
                top: 55%;
                left: 50%;
                transform: translate(-50%,-55%);

                img {
                    width: 100%;
                }
            }

            .web_flex {
                flex-direction: column;
                text-align: right;
                width: max(30px, 10%);
                position: relative;
                z-index: 10;

                .facebook{
                    display: block;
                    color: $text-color;
                    font-size: clamp(.8rem, .8vw, 1rem);
                    position:absolute;
                    z-index: 100;

                }

                .social_container,.social_div {
                    display: none
                }

                
            }
        }

        .content {
            width: 70%;
            margin: auto;
            display: flex;
            justify-content: space-between;
            position: absolute;
            top: 20%;
            left: 50%;
            transform: translate(-50%, -20%);
            align-items: center;

            .div1 {
                text-align: center;

                p {
                    display: block;
                    text-decoration: none;
                    font-size: clamp(1.5rem, 2vw, 1.5rem);
                    color: #6A6A6A;


                }
            }

            .div2 {
                position: relative;

                .contractor_content {

                    border-left: 1px solid grey;
                    border-right: 1px solid gray;
                    display: block;
                    padding: 0 30px;

                    p {

                        font-size: 20px;
                        color: #6A6A6A;
                        margin: 0;
                        text-transform: capitalize;

                    }
                }

                .line {
                    width: 100%;
                    height: 10px;
                    margin-top: 10px;
                    display: grid;
                    place-items: center;

                    p {
                        width: 50%;
                        border-top: 1px solid grey;
                    }
                }
            }

            .div3 {


                p {

                    font-size: 20px;
                    color: #6A6A6A;
                    margin: 0;
                    text-transform: capitalize;

                }
            }
        }

        .banner {
            width: 100%;


            .banner_trey {
                width: 100%;
                height: inherit;
                display: flex;
                gap: 10px;
                position: absolute;
                bottom: 0;

                .slider_images {
                    width: calc(100%/3);
                    height: 60vh;
                    position: relative;
                    cursor: pointer;
                    color: white;
                    overflow: hidden;
                    
                   
                    

                    .image_main {
                        width: 100%;
                        height: inherit;
                        object-fit: cover;
                        
                       

                    }

                    .content_details {
                        display: flex;
                        width: 100%;
                        gap: 10px;
                        align-items: center;


                        .details {
                            display: none;
                            opacity: 0;
                            width: 50%;
                            padding-left: max(20px, 5%);

                            h5 {
                                font-size: clamp(1rem, 2vw, 1.1rem);
                                margin: 0;
                            }

                            p {
                                font-size: .9rem;
                                margin-bottom: 0;
                            }

                        }
                    }

                    .overlay {
                        width: 100%;
                        height: 100%;
                        background-color: $color-primary;
                        position: absolute;
                        mix-blend-mode: hard-light;
                        opacity: 1;
                        top: 0;
                        cursor: pointer;
                    }

                    .gradient_overlay {
                        width: 100%;
                        height: 100%;
                        background-image: linear-gradient(to top, black, transparent);
                        position: absolute;
                        top: 0;
                        opacity: .8;
                    }

                    :is(&:nth-child(2),
                        &:nth-child(3)) {
                        .overlay {
                            background-color: black;
                            opacity: 1;
                            mix-blend-mode: color;
                        }
                    }

                    .slider_content {
                        width: 90%;
                        position: absolute;
                        display: flex;
                        justify-content: space-between;
                        align-items: flex-end;
                        left: 50%;
                        bottom: 3%;
                        transform: translate(-50%, -3%);



                        h1 {
                            width: 80%;
                            // font-size: clamp(1rem,3vw,4.5rem);
                            font-size: 1.9rem;
                            color: white;
                            font-weight: 100;
                            margin: 0;
                            line-height: 1.1;
                            font-family: solomon-sans-light;
                            text-transform: uppercase;
                        }

                        img {
                            max-width: 13%;
                        }



                    }

                   

                    // .slider_image:hover .image_main{
                    //     width: 100%;
                    //     height: 70vh;
                    // }
                }
            }

        }

    }
    
}


@media screen and (min-width:500px) {
    body{
        .slider_images {
      
            .image_main{
                transition: 0.5s;
            }
    
            &:hover .image_main{
                width: 100% !important;
                height: 70vh !important;
            }
    
            
        }
    }
   
}

@media screen and (max-width:500px) {
    body {

        .desktop_socials{
            display: none;
        }

        .social_div{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-end;
            gap: 30px;

            width: 30px;
            height: 30px;

            // border: 1px solid rgb(19, 17, 17);
            border-radius: 20px;
            
            background-color: black;
            position: absolute;
            z-index: 5;
            right: 3.5%;
            top: 3.5%;
            transform: translateY(-3%,-3.5%);
            
            
            a{
                width: 30px;
                display: block;
              img{
                    width: 100%;
                    height: auto;
                    object-fit: contain;
                    border-radius: 50%;
                    opacity: 0;
                }
            }

           
            
        }

        .main_container {

            .flex_box {
                border-bottom: none;
                .flex_contact {

                    .contact_text {
                        margin-left: 10px;

                        p{
                            font-size: .75rem;
                            text-decoration: none;
                            color: gray;
                        }
                        
                    }

                    .contact{
                        .contact_icon{
                            width: 90%;
                        }

                        .contact_info{
                            width: 6.8%;
                            height: 20px;
                            top: 50%;
                            transform: translate(-2%,-50%);

                            .container{
                                flex-direction: column;
                                justify-content: space-around;
                                align-items: flex-start;

                                div{
                                    width: 80%;
                                   justify-content: flex-start;
                                   padding-left: 10px;
                                   text-decoration: none;
                                    p{
                                        margin: 0;
                                    }

                                    img{
                                        width: 8.5%;
                                        height: auto;
                                    }
                                }

                                .email{
                                    img{
                                       opacity: 0;
                                    }
                                }
                            }
                        }
                    }
                }

                

                .web_flex {
                    
                    a {
                        display: none;
                    }
                    

                    .social_container{
                        display: block;
                        background-color: black;
                        width: 85%;
                        border-radius: 50%;
                        display: grid;
                        place-items: center;
                        aspect-ratio: 1 auto;
                        pointer-events: auto;

                        img{
                            width: 40%;
                            filter: invert(1);
                        }
                    }
                   

                   
                }
            }

            .content {
                display: flex;
                justify-content: center;
                top: 17%;
                transform: translate(-50%, -17%);


                .div2,
                .div3 {
                    display: none;

                }

                .div1 {
                    p {
                        margin: 0;
                        text-transform: capitalize;
                        text-align: center;
                    }
                }
            }

            .banner {

                .banner_trey {
                    flex-direction: column;

                    .slider_images {
                        width: 100%;
                        height: 7dvh;
                        overflow: hidden;



                        .content_details {
                            width: 80%;
                            color: white;
                            flex-direction: column;
                            align-items: flex-start;

                            h5,
                            p {
                                margin: 15px 0;
                            }

                            p {
                                font-size: .8rem;
                                margin-bottom: 0;
                            }

                            .details {
                                display: block;
                                opacity: 1;
                                padding-left: 0;
                                border: none;
                                width: 100%;

                                p {
                                    font-size: .8rem;
                                    line-height: 1.7;
                                    font-family: solomon-sans-light;
                                }

                            }
                        }

                        .gradient_overlay {
                            opacity: 1;

                        }

                        &:first-child {
                            height: 50dvh;

                            .overlay {
                                opacity: 0;
                            }

                            .gradient_overlay {
                                opacity: 1;

                            }

                            h1 {
                                font-size: 2.5rem;
                            }

                            .slider_content {
                                img {
                                    transform: rotate(180deg);
                                }
                            }

                            .image_main {}
                        }

                        &:nth-child(2) {
                            .slider_content {
                                .content_details {

                                    h5,
                                    p {
                                        display: none;
                                    }
                                }
                            }
                        }

                        &:nth-child(3) {
                            .slider_content {
                                .content_details {

                                    h5,
                                    p {
                                        display: none;
                                    }
                                }
                            }
                        }



                        img {
                            width: 10%;
                        }




                    }
                }
            }
        }

    }
}

