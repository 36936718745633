@media screen and (min-width:1919px) {
    .main_container{
        .flex_box{
            .flex_contact{
                .contact{
                    .contact_info{
                        // width: min(40px,10%) !important;

                        width: 40px;
                    }
                }
            }
        }
    }
}
